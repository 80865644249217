/**
 * service post Template
 */
/* Import section */
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Bannerwithcta from "../components/bannerwithcta"
import Blockservice from "../components/blockservice"
import Herotype2 from "../components/herotype2"
import Layout from "../components/layout"
import Sectionchecklist from "../components/sectionchecklist"
import Seo from "../components/seo"
import Titledecorative from "../components/titledecorative"
import "../styles/templates/servicepost.scss"


/* Declaration function */
const ServicePostTemplate = ({ data }) => {

  //Const data
  const dataPage = data.wpService
  const dataAcf = data.wpService.acfPostService
  var classPage = dataPage.slug

  //Array for Checklist benefits with CTA
  let arrItemschecklist = []
  dataAcf.repeaterBenefitsService.map( (benefit)  => (
    arrItemschecklist.push({textChecklist: benefit.itemBenefitService})
  ))

  //Array for Features service
  let arrIconService = []
  if(dataPage.slug === "service-support"){
    arrIconService = ["clock", "people", "lock"]
  }else{
    arrIconService = ["display", "server", "cursor"]
  }

  let arrFeaturesService = []
  dataAcf.repeaterFeaturesService.map( (feature, index)  => (
    arrFeaturesService.push({iconFeature: arrIconService[index], titleFeature: feature.titleFeatureService, descFeature: feature.descriptionFeatureService})
  ))

  //Const data Options
  const dataOptionsbanner = data.options.acfPageOptions
  var arrOptionsbanner = []
  arrOptionsbanner = dataOptionsbanner.repeaterBannerCta.filter(function(element){
    return element.topicBannerCta === "demo"
  })

  return (
    <Layout>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
      />

      {/* Hero */}
      <section className="section-hero-service top-section">
        <Herotype2 h1title={dataAcf.titleHeroService} textIntro={dataAcf.introHeroService} colorBar="orange" />
      </section>


      {/* Benefits service */}
      <Sectionchecklist
        classSectionChecklist="section-benefits-service"
        titleSectionChecklist={dataAcf.titleBenefitsService}
        textSectionChecklist={dataAcf.textBenefitsService}
        arrItemschecklist= {arrItemschecklist}
        ctaLabelChecklist={dataAcf.ctaLabelBenefitsService}
        ctaLinkChecklist={dataAcf.ctaLinkBenefitsService}
        colorChecklist="orange"
        colorThemeSection="blue"
        colorCtaList="white-format"
      />

      {/* Features service */}
      <section className={`section-features-service ${classPage}`}>
        <div className="wrap-features-service wrap-maincontent">
          <Titledecorative title={dataAcf.titleFeaturesService} colorBar="orange" />
          <div className="flex-box">
            <div className="image-ambiance">
              <GatsbyImage image={dataAcf.imageFeaturesService.localFile.childImageSharp.gatsbyImageData} alt="about us ambiance" />
            </div>
            <div className="intro first-body" dangerouslySetInnerHTML={{ __html:dataAcf.textFeaturesService}} />
            <div className="wrap-box-service">
              <Blockservice arrServices={arrFeaturesService} />
            </div>
          </div>
        </div>
      </section>

      {/* Banner CTA Benefit */}
      <Bannerwithcta
        classBanner=""
        titleBanner={arrOptionsbanner[0].titleBannerCta}
        textBanner={arrOptionsbanner[0].textBannerCta}
        ctaLabelBanner={arrOptionsbanner[0].ctaLabelBanner}
        ctaLinkBanner={arrOptionsbanner[0].ctaLinkBanner}
      />
    </Layout>
  )
}

/* Export template informations */
export default ServicePostTemplate

//QUERY GRAPHSQL ON POST
export const query = graphql`
  query($id: Int!) {
    wpService(databaseId: {eq: $id}) {
      title
      slug
      acfPostService {
        ctaLabelBenefitsService
        ctaLinkBenefitsService
        imageFeaturesService {
          localFile {
            childImageSharp {
              gatsbyImageData (layout: CONSTRAINED, width: 900, aspectRatio: 1.5)
            }
          }
        }
        introHeroService
        repeaterBenefitsService {
          itemBenefitService
        }
        repeaterFeaturesService {
          descriptionFeatureService
          titleFeatureService
        }
        textBenefitsService
        textFeaturesService
        titleBenefitsService
        titleFeaturesService
        titleHeroService
      }
      seo {
        title
        metaDesc
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterBannerCta {
          ctaLabelBanner
          ctaLinkBanner
          textBannerCta
          titleBannerCta
          topicBannerCta
        }
      }
    }
  }
`
