/**
 * Const with params element
 * Block service component
 * Display a block of service with icons
 */
/* Import section */
import React from "react"
import { IconContext } from "react-icons"
import { BsClock, BsCursor, BsDisplay, BsPeople, BsPhone, BsServer, BsShieldLock, } from "react-icons/bs"
import "../styles/components/blockservice.scss"

/* Declaration function */
const Blockservice= ( { arrServices } ) => {
  return (
    <div className="block-service">
      {arrServices.map((service, index )  => (
        <div key={index} className="box-item-service">
          <span className="icon-item-service">
            <IconContext.Provider value={{ className: "icon-service" }}>
              {service.iconFeature === "display" && <BsDisplay />}
              {service.iconFeature === "clock" && <BsClock />}
              {service.iconFeature === "cursor" && <BsCursor />}
              {service.iconFeature === "lock" && <BsShieldLock />}
              {service.iconFeature === "people" && <BsPeople />}
              {service.iconFeature === "phone" && <BsPhone />}
              {service.iconFeature === "server" && <BsServer />}
            </IconContext.Provider>
          </span>
          <div className="content-item-service">
            <h3>{service.titleFeature}</h3>
            <div dangerouslySetInnerHTML={{ __html:service.descFeature}}/>
          </div>
        </div>
      ))}
    </div>
  )
}

/* Export function */
export default Blockservice
